<template>
  <div class="zkware-landing">
    <el-main class="content">
      <el-header id="header" class="navbar">
        <HeaderMenu />
      </el-header>
      <div class="section">
        <section class="aboutus">
          <about-us />
        </section>
      </div>
    </el-main>
    <el-divider></el-divider>
    <el-footer>©2024 ZKware, All Rights Reserved.</el-footer>
  </div>
</template>

<script>
import AboutUs from '@/components/details/AboutUs.vue'
import HeaderMenu from "@/components/HeaderMenu.vue";
import { ElMain, ElHeader, ElDivider, ElFooter } from 'element-plus'

export default {
  name: 'AboutUsView',
  components: {
    HeaderMenu,
    AboutUs,
    ElMain,
    ElHeader,
    ElDivider,
    ElFooter
  }
}
</script>
<style scoped>
.zkware-landing {
  font-family: Arial, sans-serif;
}

.zkware-logo {
  font-family: Arial, sans-serif;
  font-size: 72px;
  font-weight: bold;
  color: white;
  display: inline-block;
}

.zkware-logo-div {
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: white;
  display: inline-block;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
}
.content {
  padding-top: 60px; /* 确保内容不被固定头部遮挡 */
}

.section {
  background-color: #fff;
  border-radius: 10px;
}

.hero {
  text-align: center;
  padding: 100px 0;
  background-image: url('../assets/bak.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.powered-by {
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 20; */
  /* height: 100px; */
}

.powered-by-img img {
  padding: 0 5%;
  width: 150px;  /* 设置固定宽度 */
  height: 100px;  /* 设置固定高度 */
  object-fit: contain; /* 保持图片比例，适应容器 */
}
</style>