<template>
  <div class="content-section">
    <h2 class="section-title">{{ title }}</h2>
    <p class="section-description">{{ description }}</p>
    <el-row :gutter="20">
      <el-col :span="8" v-for="(item, index) in items" :key="index">
        <el-card class="flexible-card">
          <div class="image-container">
            <a :href="item.url" target="_blank" rel="noopener noreferrer">
              <el-image :src="item.imageUrl" class="full-image" />
            </a>

          </div>
          <div class="card-footer">
            <span class="item-title">{{ item.title }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ElCol, ElRow, ElCard, ElImage } from 'element-plus'
export default {
  name: 'ContentSection',
  components: { ElCol, ElCard, ElRow, ElImage },
  data() {
    return {
      title: '实盘机器人',
      description: '一键跟单功能使您能够全面体验我们依托于先进大模型算法的高胜率交易策略，以及包括动态仓位管理和自动止盈止损在内的多项高级功能。',
      items: [
        {
          imageUrl: require('@/assets/share-poster.jpeg'),
          url: 'https://www.binance.com/zh-CN/copy-trading/lead-details/4153171244665001984?timeRange=30D',
          title: '中频震荡组合'
        },
        {
          imageUrl: require('@/assets/share-poster-2.jpeg'),
          url: 'https://www.binance.com/zh-CN/copy-trading/lead-details/4153168794588141312?timeRange=30D',
          title: '现货慢牛组合'
        }
      ]
    }
  }
}
</script>

<style scoped>
.content-section {
  margin-bottom: 20px;
}

.flexible-card {
  margin-bottom: 20px;
}

.image-container {
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.full-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-footer {
  padding: 10px;
}

.item-title {
  display: block;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>