import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StrategyView from "@/views/StrategyView.vue";
import AllNewsView from "@/views/AllNewsView.vue"
import PricePredictionView from "@/views/PricePredictionView.vue";
import AboutUsView from "@/views/AboutUsView.vue";
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/strategy-detail',
    name: 'StrategyDetail',
    component: StrategyView
  },
  {
    path: '/all-news',
    name: 'AllNews',
    component: AllNewsView
  },
  {
    path: '/price-prediction',
    name: 'PricePredictionView',
    component: PricePredictionView
  },
  {
    path: '/about-us',
    name: 'AboutUsView',
    component: AboutUsView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = "ZKware.AI 全天候加密智库"
  next()
})

export default router
