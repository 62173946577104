<template>
<!--  所有新闻列表-->
  <el-card class="volatility-card">
    <el-page-header @back="goBack">
      <template #content>
        <span class="text-large font-600 mr-3">{{title}}</span>
      </template>
    </el-page-header>
    <div v-if="items.length">
      <el-tabs class="demo-tabs">
        <el-tab-pane v-for="(item, index) in items" :label="item.description" :key="index">
<!--          <div class="strategy-header">-->
<!--            <h2>{{ item.title }}</h2>-->
<!--          </div>-->
          <el-card class="fixed-size-card">
            <div class="card-content">
              <ul>
                <li v-for="(dataItem, dataIndex) in sortedData(item.data)" :key="dataIndex" class="infinite-list-item">
                  <a :href="dataItem.link" target="_blank" class="link"><span>{{ dataItem.title }}</span>({{ formatDate(dataItem.date) }})</a>
                </li>
              </ul>
            </div>
<!--            <div class="card-footer">-->
<!--              <span>{{ item.description }}</span>-->
<!--            </div>-->
          </el-card>
        </el-tab-pane>
      </el-tabs>

    </div>
  </el-card>
</template>

<script>
import axios from "axios";
import { ElCard, ElPageHeader, ElTabs, ElTabPane} from 'element-plus';

export default {
  name: 'AllNews',
  components: {
    ElPageHeader,
    ElCard,
    ElTabs,
    ElTabPane
  },
  data() {
    return {
      title: '热点精选',
      description: 'AI网罗全网最新资讯，甄别有效信息，为您把握交易先机',
      items: []
    }
  },
  mounted() {
    this.fetchCryptoNews();
  },
  methods: {
    goBack() {
      window.history.back();
    },
    async fetchCryptoNews() {
      try {
        const response = await axios.get('https://ai.zkware.net/api/v1/crypto_all_news');
        this.items = this.convertToItems(response.data.data);
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    },
    convertToItems(data) {
      const chartTemplates = [];
      Object.keys(data).forEach(symbol => {
        const symbolData = data[symbol];
        const chart = {
          title: `${symbol.replace('USDT', '')}`,
          description: `${symbol.replace('USDT', '')}最新资讯`,
          data: symbolData
        };
        chartTemplates.push(chart);
      });
      return chartTemplates;
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    },
    sortedData(data) {
      if (!data) return [];
      return data.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  }
};
</script>

<style scoped>
.volatility-card {
  margin: 20px;
}

.strategy-header {
  margin-bottom: 30px;
}

.strategy-header h2 {
  font-size: 24px;
  color: #303133;
  margin: 0;
}

.section h3 {
  font-size: 18px;
  color: #303133;
  margin-bottom: 15px;
}
.link {
  display: block;
  text-align: left;
  color: #409EFF;
  text-decoration: none;
  font-size: 0.95em;
  line-height: 1.4;
}

.link:hover {
  text-decoration: underline;
}
</style>