<template>
<!--  策略详情静态页面-->
  <div class="strategy-header">
    <h2>{{ chart.title }}</h2>
  </div>
  <!-- 实盘净值部分 TODO 静态页面 -->
  <div class="section">
    <h3 style="text-align: left;">实盘净值:</h3>
    <div class="chart-container" v-if="chart.data">
      <line-chart :data="chart.data" :options="chartOptions" />
    </div>
  </div>
  <!-- 策略说明部分 -->
  <div class="section">
    <h3 style="text-align: left;">{{ chart.title }}</h3>
    <div class="strategy-desc">
      <el-alert type="info" :closable="false" show-icon>
<!--        {{chart.detail}}-->
        <span v-html="chart.detail" style="text-align: left; display: block;"></span>
      </el-alert>
    </div>
  </div>

  <div class="section" v-if="chart.showProfit">
    <h3>预期收益</h3>
    <el-image :src="chart.image_url" class="full-image" />
  </div>

</template>

<script>
import { Line as LineChart } from 'vue-chartjs'
import { zhCN } from 'date-fns/locale';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler } from 'chart.js'
import 'chartjs-adapter-date-fns';
import {ElAlert, ElImage} from 'element-plus'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler)

export default {
  name: 'AdvancedStrategies',
  components: {
    ElImage,
    LineChart,
    ElAlert
  },
  props: {
    chart: {}
  },
  data() {
    return {
      title: '波动率策略',
      description: '利用期权等衍生工具，拥抱加密货币的高波动性',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              locale: zhCN,
              tooltipFormat: 'yyyy-MM-dd',
              displayFormats: {
                day: 'd'
              }
            },
            ticks: {
              callback: function(value, index, values) {
                // 获取日期
                const date = new Date(value);
                const day = date.getDate(); // 获取日期
                const month = date.getMonth(); // 获取月份
                if(index === 0){
                  return `${day}`;
                }
                const oldMonth = new Date(values[index - 1].value).getMonth(); // 确保直接访问的是日期值
                if (oldMonth !== month) {
                  // 如果是新月份，显示月份和日期
                  return `${month + 1}月`;
                } else {
                  // 否则只显示日期
                  return `${day}`;
                }
              }
            }
          }
        }
      },
    }
  },
}

</script>

<style scoped>
.volatility-card {
  margin: 20px;
}

.strategy-header {
  margin-bottom: 30px;
}

.strategy-header h2 {
  font-size: 24px;
  color: #303133;
  margin: 0;
}

.section {
  margin-bottom: 30px;
}

.section h3 {
  font-size: 18px;
  color: #303133;
  margin-bottom: 15px;
}

.chart-container {
  height: 300px;
  margin-top: 20px;
}

.strategy-desc {
  margin: 15px 0;
}

.dark-theme {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 4px;
}
</style>