<template>
  <div class="aboutus">
    <h1>关于我们</h1>
    <h3>ZKware的核心愿景</h3>
    <p>
      ZKware是由一群拥有共同理想的人工智能研究者、区块链技术专家、加密经济学者和量化投资专家共同发起的去中心化自治组织（DAO）。我们的核心愿景是通过创新性地整合人工智能、区块链技术、零知识证明和加密资产投资，将这些前沿技术普及给大众。目前，ZKware LAB负责推动并实现这一伟大愿景，随着DAO的不断发展，我们将逐步将管理权交给社区。我们愿景的首要步骤是让社区成员从交易中获益，同时我们也在积极布局零知识证明（ZKP）、大型语言模型（LLM）等领域，ZKware的名字正是源自零知识证明技术。
    </p>
    <h3>大模型重新定义交易</h3>
    <p>专业投资机构与普通投资者的主要区别在于，除了拥有多样化的盈利策略外，他们还掌握着先进的风险控制手段、全天候的仓位监控系统、期权风险对冲工具等。我们坚信，随着大型语言模型带来的人工智能革命，过去需要大量人力物力开发的算法交易系统、盈利因子、量化交易策略等都将被重新定义。DAO将免费为社区成员提供这些能力，让每个人都能享受到以往只有专业投资者才能负担成本的高端服务，真正实现交易平权。    </p>
    <h3>共建生态系统</h3>
    <p>我们欢迎社区成员共享交易策略、分享新项目和机会。未来，DAO将发行治理代币，并将所有收益，包括带单分佣等，回馈给社区。我们致力于打造一个互利共赢的生态系统。
    </p>
    <h3>联系我们</h3>
    <ul>
      <li><a href="https://discord.gg/waU4rxZhQE" target="_blank" rel="noopener">Discord</a></li>
      <li><a href="https://x.com/ZKwareAI" target="_blank" rel="noopener">X</a></li>
    </ul>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.aboutus {
  margin: auto 20%;
  /* padding: 200px; */
  text-align: left;
}
.aboutus h1 {
  text-align: center;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px 0 0;
}
a {
  color: #409EFF;
}
</style>
