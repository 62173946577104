<template>
  <div class="content-section">

    <div class="highlights-header">
      <h2 class="section-title">{{ title }}</h2>
      <div class="section-description subtitle">
        <span>{{ description }}</span>
        <router-link to="/all-news" class="view-all" style="color: #409eff; text-decoration: none;">
          查看更多 >
        </router-link>
      </div>
    </div>

<!--    <h2 class="section-title">{{ title }}</h2>-->
<!--    <p class="section-description">{{ description }}</p>-->
    <el-row :gutter="20">
      <el-col :span="8" v-for="(item, index) in items" :key="index">

        <el-card class="fixed-size-card">
          <div class="card-content">
            <ul>
              <li  v-for="(dataItem, dataIndex) in sortedData(item.data)" :key="dataIndex" class="infinite-list-item">
                <a :href="dataItem.link" target="_blank" class="link"><span>{{ dataItem.title }}</span>({{ formatDate(dataItem.date) }})</a>
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <span>{{ item.description }}</span>
          </div>
        </el-card>

      </el-col>
    </el-row>
  </div>

</template>

<script>
import axios from "axios";
import {ElCard, ElCol, ElRow} from 'element-plus'
export default {
  name: 'ContentSection',
  components: { ElCard, ElRow, ElCol },
  data() {
    return {
      title: '热点精选',
      description: 'AI网罗全网最新资讯，甄别有效信息，为您把握交易先机',
      items: [
        {
          title: 'Federal Reserve decision looms, UK inflation takes pre-BoE dive',
          data: []
        }
      ]
    }
  },
  mounted() {
    this.fetchPredictPrices();
  },
  methods: {
    async fetchPredictPrices() {
      try {
        const response = await axios.get('https://ai.zkware.net/api/v1/crypto_news'); // 替换为你的 API URL
        this.items = this.convertToItems(response.data.data)
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    },
    convertToItems(data) {
      const chartTemplates = [];
      Object.keys(data).forEach(symbol => {
        const symbolData = data[symbol];
        const chart = {
          title: `${symbol.replace('USDT', '')}`,
          description: `${symbol.replace('USDT', '')}最新资讯`,
          data: symbolData
        };

        chartTemplates.push(chart);
      });
      return chartTemplates;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // 转换为 yyyy-mm-dd 格式
      return date.toISOString().split('T')[0];
    },
    sortedData(data) {
      return data.slice().sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
    }
  }


}
</script>

<style scoped>
.content-section {
  margin-bottom: 10px;
}
.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.section-description {
  color: #666;
  margin-bottom: 20px;
}
.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 14px;
}

.fixed-size-card {
  height: 400px;
  display: flex;
  flex-direction: column;
}

.card-content {
  height: 320px;
  flex-grow: 1;
  overflow-y: auto;
}

.card-footer {
  padding: 10px;
  border-top: 1px solid #ebeef5;
  font-size: 0.9em;
  color: #606266;
}

.news-item {
  margin-bottom: 10px;
}

.link {
  display: block;
  text-align: left;
  color: #409EFF;
  text-decoration: none;
  font-size: 0.95em;
  line-height: 1.4;
}

.link:hover {
  text-decoration: underline;
}

.date {
  font-size: 0.85em;
  color: #909399;
  margin-left: 5px;
}
</style>