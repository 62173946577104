<template>
<!--  策略盈利图表-->
  <div class="card w-full max-w-4xl p-4 border rounded-lg shadow-sm">
<!--    <h2 class="text-xl font-bold mb-4">Multiple Options Plotter</h2>-->
    <div class="chart-container">
      <Line
          :data="chartData"
          :options="chartOptions"
          class="w-full h-[400px]"
      />
    </div>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

export default {
  name: 'OptionsPlotter',
  components: { Line },
  data() {
    return {
      spot: 72000,
      spotRange: 80,
      options: [
        {
          opType: 'p',
          strike: 63000,
          trType: 'b',
          opPr: 72000 * 0.07,
          contract: 1
        },
        {
          opType: 'c',
          strike: 63000,
          trType: 'b',
          opPr: 72000 * 0.08,
          contract: 1
        }
      ]
    }
  },
  computed: {
    priceRange() {
      const minPrice = this.spot * (1 - this.spotRange/100)
      const maxPrice = this.spot * (1 + this.spotRange/100)
      const steps = 50
      const stepSize = (maxPrice - minPrice) / steps

      const prices = []
      for (let price = minPrice; price <= maxPrice; price += stepSize) {
        prices.push(price)
      }
      return prices
    },

    chartData() {
      const prices = this.priceRange
      const putPayoffs = prices.map(price => this.calculatePutPayoff(
          price,
          this.options[0].strike,
          this.options[0].opPr,
          this.options[0].trType === 'b'
      ))

      const callPayoffs = prices.map(price => this.calculateCallPayoff(
          price,
          this.options[1].strike,
          this.options[1].opPr,
          this.options[1].trType === 'b'
      ))

      const combinedPayoffs = prices.map((_, i) => putPayoffs[i] + callPayoffs[i])

      return {
        labels: prices.map(price => Math.round(price)),
        datasets: [
          {
            label: '1 Long Put ST: 63000',
            data: putPayoffs,
            borderColor: '#8884d8',
            tension: 0.1,
            fill: false
          },
          {
            label: '1 Long Call ST: 63000',
            data: callPayoffs,
            borderColor: '#ffa500',
            tension: 0.1,
            fill: false
          },
          {
            label: 'Combined',
            data: combinedPayoffs,
            borderColor: '#000',
            backgroundColor: 'rgba(130, 202, 157, 0.2)',
            tension: 0.1,
            fill: true
          }
        ]
      }
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear',
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            },
            ticks: {
              callback: value => value.toLocaleString()
            }
          },
          y: {
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            },
            ticks: {
              callback: value => value.toLocaleString()
            }
          }
        },
        plugins: {
          legend: {
            position: 'top'
          },
          annotation: {
            annotations: {
              spotLine: {
                type: 'line',
                xMin: this.spot,
                xMax: this.spot,
                borderColor: 'red',
                borderWidth: 1,
                borderDash: [5, 5]
              },
              zeroLine: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: '#666',
                borderWidth: 1
              }
            }
          }
        }
      }
    }
  },
  methods: {
    calculatePutPayoff(spotPrice, strike, premium, isLong) {
      const multiplier = isLong ? 1 : -1
      return multiplier * Math.max(strike - spotPrice, 0) - (isLong ? premium : -premium)
    },

    calculateCallPayoff(spotPrice, strike, premium, isLong) {
      const multiplier = isLong ? 1 : -1
      return multiplier * Math.max(spotPrice - strike, 0) - (isLong ? premium : -premium)
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 400px;
  width: 100%;
}
</style>