<template>
  <div class="new-narratives">
    <h1>新叙事新机会</h1>
    <p>加密经济永远不缺新的叙事，远的有早几年的Defi Summer、加密猫、GameFi、平台币、新Layer1、DePIN、ZKRollup等等，近的有23年的比特币铭文，24年至今加密经济虽然缺少跟去年比特币铭文相提并论的新叙事逻辑，但也还围绕着“BTC减半”、“现货ETF上市”、“降息”、“各种诉讼赔偿结案”等主旋律，出现了诸如老币换发新春（SOL）、Layer1新王有望引领新一轮牛市等热点或舆情，ZKware LAB持续跟踪相关赛道的投资机会，并无偿分享给社区。</p>
    
    <h3>SOL</h3>
    <p>
      <a href="https://solana.com/" target="_blank">SOL</a>是上一个牛市周期最大的获益者之一，最核心的推动力量来自于FTX公开支持SOL，成也FTX，败也FTX，随着FTX破产，SOL也在熊市中苦苦挣扎，但在这一波牛市反弹中，SOL一年之内从23年10月的20块一路上涨到如今的150+，最高几乎达到了上个牛市周期的最高点，那么SOL到底还值不值得买入？ 
    </p>
    
    <h3>SUI</h3>
    <p><a href="https://sui.io/" target="_blank">SUI</a>是MOVE生态3大公链之一，最大的叙事逻辑来自于如果加密经济真正面向普通大众，在底层基础设施上就要进行革命性的创新，特别是智能合约需要采用更安全的编程语言，这也是MOVE发明的初衷。SUI最近在油管上知名频道<a href="https://www.youtube.com/watch?v=EfNlejjM1Cc">CoinBureau</a> 、<a href="https://www.youtube.com/watch?v=gjN8gwtvioQ">Paul Barron</a>、<a href="https://www.youtube.com/watch?v=GJOxJWLRWv0">Think Crypto</a> 、<a href="https://www.youtube.com/watch?v=rnMzUH7oNk4">脑哥区块链</a> 、<a href="https://www.youtube.com/watch?v=6GdQ79WVAAM">区块链日报</a> 等获得频繁曝光。</p>
    
    <h3>XRP</h3>
    <p><a href="https://xrpl.org/"  target="_blank">XRP</a>23年10月赢得和SEC的官司，并直接催生了这一轮加密货币的全面上涨，只是可惜的是XRP自己的涨幅还远远未达到平均水平，更是远低于社区的预期。ZKware LAB也重仓持有这个资产，并持续跟踪相关进展，以下通过Chatgpt每日跟踪精选关于XRP的舆情，与社区分享。
    </p>
    
    <h3>ALEO</h3>
    <p><a href="https://aleo.org/" target="_blank">ALEO</a>简单的说来，就是构建了一个零知识证明的开发平台。作为最为知名的VC币之一，ALEO生而不凡，拿到了A16Z等众多知名机构的风险投资，ALEO也于24年9月主网上线，代币交易也上了几个交易所。ZKware LAB从Testnet2开始就深度参与这个项目，并且也重仓持有aleo，并持续跟踪相关进展，以下通过Chatgpt每日跟踪精选关于ALEO的舆情，与社区分享。

</p>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.new-narratives {
  margin: auto 20%;
  /* padding: 200px; */
  text-align: left;
}
.new-narratives h1 {
  text-align: center;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px 0 0;
}
a {
  color: #409EFF;
}
</style>
