<template>
<!--  预测价格列表-->
  <el-card class="volatility-card">
    <el-page-header @back="goBack">
      <template #content>
        <span class="text-large font-600 mr-3"> {{title}} </span>
      </template>
    </el-page-header>
    <div v-if="charts.length">
      <el-tabs class="demo-tabs">
        <el-tab-pane v-for="(chart, index) in charts" :label="chart.title" :key="index">
          <div class="chart-container" v-if="chart.data">
            <line-chart :data="chart.data" :options="chartOptions" />
          </div>
        </el-tab-pane>
      </el-tabs>

    </div>
  </el-card>
</template>

<script>
import axios from "axios";
import { ElCard, ElPageHeader, ElTabs, ElTabPane  } from 'element-plus';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import {Line as LineChart} from "vue-chartjs";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
export default {
  name: 'AllPricePrediction',
  components: {
    ElPageHeader,
    ElCard,
    LineChart,
    ElTabs,
    ElTabPane
  },
  data() {
    return {
      title: '专家看盘',
      description: 'AI时刻监测价格走势和一系列指标，为您提供最新分析',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      charts: []  // 用于存储转换后的图表数据
    }
  },
  mounted() {
    this.fetchPredictPrices();
  },
  methods: {
    goBack() {
      window.history.back();
    },
    async fetchPredictPrices() {
      try {
        const response = await axios.get('https://ai.zkware.net/api/v1/predict_price'); // 替换为你的 API URL
        this.charts = this.convertToCharts(response.data)
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    },
    convertToCharts(data) {
      const chartTemplates = [];

      Object.keys(data).forEach(symbol => {
        const symbolData = data[symbol];
        const chart = {
          title: `${symbol.replace('USDT', '')}价格预测`,
          author: 'ZKware.AI',
          authorAvatar: require('@/assets/logo.png'),
          data: {
            labels: symbolData.dates,
            datasets: [
              {
                label: 'Close',
                data: symbolData.close_price,
                borderColor: '#41B883',
                fill: false
              },
              {
                label: 'Predicted',
                data: symbolData.prediction,
                borderColor: '#0D83FE',
                fill: false
              }
            ]
          }
        };

        chartTemplates.push(chart);
      });
      return chartTemplates;
    }
  }
};
</script>

<style scoped>
.volatility-card {
  margin: 20px;
}

.strategy-header {
  margin-bottom: 30px;
}

.strategy-header h2 {
  font-size: 24px;
  color: #303133;
  margin: 0;
}

.section h3 {
  font-size: 18px;
  color: #303133;
  margin-bottom: 15px;
}
.link {
  display: block;
  text-align: left;
  color: #409EFF;
  text-decoration: none;
  font-size: 0.95em;
  line-height: 1.4;
}

.link:hover {
  text-decoration: underline;
}
</style>